import './App.css';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function App() {
  return (
    <Container sx={{marginTop: '50px'}}>
        
        <Typography variant="h5">
          January 15th, 2013 marked an epic historical event: I joined Unicorn Rentals to become Unicorn
          Rentals’ irreplaceable COO (=second in command). 
        </Typography>
        <br />
        <Typography variant="body1">
          Just 9 years later I find myself being CEO of Infinidash, looking to hire my very own second in command!
        </Typography>
        <Typography>
          My rationale for joining the Unicorn.Rentals family back then was simple: 
          I was intrigued by the CEO’s quote and excuse answer to every critic: 
        </Typography>
        <br />
        <Typography>
          <b>“You miss 100% of the chances you don't take!”.</b>
        </Typography>
        <br />
        This defines our culture to this day and that’s why at Unicorn Rentals and 
        Infinidash we will never hold you back due to lack of qualifications, integrity, confidence, 
        experience, training or sense of humour. 
        In fact, we encourage you to step out of your comfort zone and do great things!
        <br />
        Every staff member has access to all systems and data regardless of experience or position. 
        It doesn't matter if you are an intern, lead developer, executive assistant, or gardener...
        you will have the access and opportunity to develop and innovate. 
        We also won't slow things down by insisting on using a test or development system. 
        <br />
        <b>EVERYONE</b> will have the capability to build great solutions right in the public production environment. 
        We know that this will speed up deployments and get products to our customers faster! 
        What good is a feature that sits in a queue waiting for testing?
        On that note, if any of you have any urgent business to discuss, 
        I am always here for you, but please check the tidal and wave forecast before 
        putting something in my calendar to avoid disappointment. 
        <br />
        <br />
        Can’t get hold of me? Just make the decision yourself!
        <br/>
        <br/>
        <Typography variant="subtitle2">
          <i>CEO of Infinidash</i>
        </Typography>
    </Container>
  );
}

export default App;
